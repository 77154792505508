<template>
  <section id="promotion">
    <v-card height="700px" color="#f0f3fb">
      <h1 class="text-center pt-5 header-title">{{ $t("promotion") }}</h1>
      <v-divider
        class="border-opacity-100 mx-auto line-divider"
        color="#0c3e5c"
      ></v-divider>

      <v-container>
        <Flickity ref="flickityPromotion" :options="flickityOptions">
          <div
            class="col-lg-6 col-md-6 col-12"
            v-for="(item, i) in item"
            :key="i"
            @click="
              openDialog(item.title, item.pathImage, item.descriptionHtml)
            "
          >
            <v-card style="background-color: #d9d9d9; border-radius: 20px">
              <v-row class="ma-0 px-0 py-3">
                <v-col
                  class="mx-auto"
                  style="justify-content: center; display: grid"
                >
                  <v-img
                    :src="item.pathImage"
                    height="400"
                    width="200"
                    style="border-radius: 20px"
                  >
                  </v-img>
                </v-col>
                <v-col>
                  <h2 style="color: #0c3e5c" >{{ item.title }}</h2>

                  <p
                    style="
                      bottom: 10px;
                      right: 30px;
                      position: absolute;
                      color: #607860;
                      font-size: 16px;
                    "
                  >
                    Read...
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </Flickity>
      </v-container>
    </v-card>
    <v-overlay :value="loading" style="z-index: 9999">
      <v-progress-circular indeterminate :size="64"> </v-progress-circular>
    </v-overlay>

    <v-dialog
      v-model="dialogPromotion"
      fullscreen
      hide-overlay
      persistent
      style="background-color: #faf7f8"
    >
      <v-card>
        <div class="py-2">
          <v-btn
            icon
            dark
            @click="closeDialog()"
            color="#0c3e5c"
            large
            style="right: 0; position: absolute"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <h1 class="text-center pt-5 header-title">{{ title }}</h1>
        <v-divider
          class="border-opacity-100 mx-auto line-divider"
          color="#0c3e5c"
        ></v-divider>

        <v-row class="ma-0 px-0 py-3" align="top" justify="center">
          <v-col cols="12" md="5" class="mx-auto promotion-display">
            <v-img
            lazy-src="https://picsum.photos/id/11/10/6"
            :src="pathImage"
              height="500"
              width="370"
              style="border-radius: 20px"
            >
            </v-img>
          </v-col>
          <v-col
            cols="12"
            md="7"
            class="mx-auto mt-5"
            style="justify-content: start; display: grid"
          >
            <p v-html="detail" ></p>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </section>
</template>
    
    <script>
import Flickity from "vue-flickity";
import Service from "@/service/axios-service";
export default {
  name: "ServiceSection",
  data() {
    return {
      flickityOptions: {
        prevNextButtons: true,
        pageDots: true,
        groupCells: true,
      },
      item: [],
      model: null,
      dialogPromotion: false,
      title: "",
      pathImage: "",
      detail: "",
      loading: false,
    };
  },
  mounted() {
    this.getPromotion();
  },
  methods: {
    getPromotion() {
      const language = localStorage.getItem("language") || "TH";

      Service.get(`promotion?Language=${language}`).then((res) => {
        this.item = res.data.data;
        setTimeout(() => {
          this.$refs.flickityPromotion.rerender();
        }, 100);
      });
    },
    closeDialog() {
      this.dialogPromotion = false;
    },
    openDialog(title, pathImage, detail) {
      this.title = title;
      this.pathImage = pathImage;
      this.detail = detail;
      this.loading = true;
      this.dialogPromotion = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
  components: {
    Flickity,
  },
};
</script>
  
<style>
.carousel-cell {
  width: 100%;
  height: 200px;
  margin-right: 10px;
  background: #8c8;
  border-radius: 5px;
  counter-increment: gallery-cell;
}
.promotion-display {
  justify-content: end;
  display: grid;
}
/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  content: counter(gallery-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}
.flickity-prev-next-button.previous {
  left: -5%;
  top: 210px;
}
.flickity-prev-next-button.next {
  right: -5%;
  top: 210px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .promotion-display {
    justify-content: center !important;
  }
  .md-display {
    justify-content: center;
    display: grid;
  }
  .flickity-prev-next-button.previous {
    left: 10% !important;
  }
  .flickity-prev-next-button.next {
    right: 10% !important;
  }
}

@media (max-width: 767.98px) {
  .promotion-display {
    justify-content: center !important;
  }
  .flickity-prev-next-button.previous {
    left: -10px;
  }
  .flickity-prev-next-button.next {
    right: -10px;
  }
}
</style>
    