<template>
  <v-app>
    <v-overlay :value="loadingPage" style="z-index: 9999">
        <v-img src="/logo-page.png" style="width: 200px" class="image-shake">
        </v-img>
    </v-overlay>
    <navigation :color="color" :flat="flat" />
    <v-main class="pt-0">
      <home />
      <service />
      <gallery />
      <promotion />
    </v-main>
    <v-scale-transition>
      <v-btn
        fab
        v-show="fab"
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
    <FooterSection />
  </v-app>
</template>

<style scoped>
.v-main {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
</style>

<script>
import navigation from "./layout/Navbar.vue";
import home from "./components/Home.vue";
import service from "./components/Service.vue";
import gallery from "./components/Gallery.vue";
import promotion from "./components/Promotion.vue";
import FooterSection from "./layout/Footer.vue";

export default {
  name: "AppMain",

  components: {
    navigation,
    home,
    service,
    gallery,
    promotion,
    FooterSection,
  },

  data: () => ({
    fab: null,
    color: "",
    flat: null,
    loadingPage: true,
  }),

  created() {
    //loading page
    setTimeout(() => {
      this.loadingPage = false;
    }, 1200);

    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "primary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
.v-application {
  font-family: "IBM Plex Sans Thai", sans-serif !important;
}
.header-title {
  font-size: 45px;
  color: #0c3e5c;
}

.image-shake {
  display: block;
  max-width: 100%;
  height: auto;
  animation: shake-logo 0.4s infinite;
}

@media (min-width: 1904px) {
  .container {
    max-width: 1250px !important;
  }
}

@keyframes shake-logo {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0eg);
  }

  75% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
.line-divider {
  height: 7px !important;
  max-height: 7px !important;
  max-width: 140px !important;
  margin-bottom: 15px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .line-divider {
    max-height: 7px !important;
    height: 7px !important;
  }
  .header-title {
    font-size: 45px !important;
  }
}

@media (max-width: 767.98px) {
  .line-divider {
    height: 5px !important;
    max-height: 5px !important;
  }
  .header-title {
    font-size: 45px !important;
  }
}
</style>
