import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
// import router from "./router";
import Flickity from 'vue-flickity';
import i18n from './plugins/i18n.js'



Vue.config.productionTip = false

new Vue({
  vuetify,
  // router,
  Flickity,
  i18n,

  render: h => h(App)
}).$mount('#app')
