<template>
  <section id="home">
    <v-carousel height="100%" show-arrows-on-hover cycle hide-delimiters>
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.pathImage"
      ></v-carousel-item>
    </v-carousel>
  </section>
</template>
  
  <script>
import Service from "../service/axios-service";
export default {
  name: "HomeSection",
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.getBanner();
  },
  methods: {
    getBanner() {
      Service.get("/banner")
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

  