<template>
  <section id="service">
    <v-card height="720px" color="secondary">
      <h1 class="text-center pt-5 header-title">{{ $t("service") }}</h1>
      <v-divider
        class="border-opacity-100 mx-auto line-divider"
        color="#0c3e5c"
      ></v-divider>

      <v-container>
        <Flickity ref="flickityService" :options="flickityOptions">
          <div
            class="col-lg-4 col-md-6 col-12 md-display"
            style="justify-content: center; display: grid"
            v-for="(item, i) in item"
            :key="i"
          >
            <v-img
              :src="item.pathImage"
              lazy-src="https://picsum.photos/id/11/10/6"
              height="365"
              width="365"
              style="border-radius: 20px"
              @click="openDialog(item.title, item.detail)"
            >
            </v-img>
            <h2 style="font-size: 18px;margin-top: 15px;" class="text-center">{{ item.title }}</h2>
            <p class="text-detail text-center">{{ item.subTitle }}</p>
          </div>
        </Flickity>
      </v-container>
    </v-card>
    <v-overlay :value="loading" style="z-index: 9999">
      <v-progress-circular indeterminate :size="64"> </v-progress-circular>
    </v-overlay>

    <v-dialog
      v-model="dialogService"
      fullscreen
      hide-overlay
      persistent
      style="background-color: #faf7f8"
    >
      <v-card>
        <div class="py-2">
          <v-btn
            icon
            dark
            @click="closeDialog()"
            color="#0c3e5c"
            large
            style="right: 0; position: absolute"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <h1 class="text-center pt-5 header-title">{{ title }}</h1>
        <v-divider
          class="border-opacity-100 mx-auto line-divider"
          color="#0c3e5c"
        ></v-divider>

        <v-container>
          <Flickity ref="flickityServiceDetail" :options="flickityOptions">
            <div
              class="col-lg-4 col-md-4 col-12 md-detail-display"
              style="justify-content: center; display: grid"
              v-for="(item, i) in detail"
              :key="i"
            >
              <v-img
                lazy-src="https://picsum.photos/id/11/10/6"
                :src="item.pathImage"
                height="400"
                width="350"
                style="border-radius: 20px"
              >
              </v-img>

              <label v-html="item.descriptionHtml" style="font-size: 16px;" class="mt-2"></label>
            </div>
          </Flickity>
        </v-container>
      </v-card>
    </v-dialog>
  </section>
</template>
    
    <script>
// import DialogService from "./dialog/dialogService.vue";
import Flickity from "vue-flickity";
import Service from "@/service/axios-service";
export default {
  name: "ServiceSection",
  data() {
    return {
      flickityOptions: {
        prevNextButtons: true,
        pageDots: true,
        groupCells: true,
      },
      item: [],
      model: null,
      dialogService: false,
      detail: [],
      title: "",
      loading: false,
    };
  },
  mounted() {
    this.getSevice();
  },
  methods: {
    openDialog(title, detail) {
      this.title = title;
      this.detail = detail;
      this.loading = true;
      this.dialogService = true;
      setTimeout(() => {
        this.$refs.flickityServiceDetail.rerender();
        this.loading = false;
      }, 1000);
    },
    getSevice() {
      const language = localStorage.getItem("language") || "TH";
      Service.get(`service?Language=${language}`).then((res) => {
        this.item = res.data.data;
        setTimeout(() => {
          this.$refs.flickityService.rerender();
        }, 1000);
      });
    },
    closeDialog() {
      this.dialogService = false;
      this.$refs.flickityServiceDetail.destroy();
    },
  },
  components: {
    Flickity,
    // DialogService,
  },
};
</script>
  
<style>
.carousel-cell {
  width: 100%;
  height: 200px;
  margin-right: 10px;
  background: #8c8;
  border-radius: 5px;
  counter-increment: gallery-cell;
}
.text-detail {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 100%;
  font-size: 16px;
}

/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  content: counter(gallery-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}
.flickity-prev-next-button.previous {
  left: -5%;
  top: 210px;
}
.flickity-prev-next-button.next {
  right: -5%;
  top: 210px;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .md-display {
    justify-content: center;
    display: grid;
  }
  .flickity-prev-next-button.previous {
    left: 10% !important;
  }
  .flickity-prev-next-button.next {
    right: 10% !important;
  }
}

@media (max-width: 767.98px) {
  .flickity-prev-next-button.previous {
    left: -10px;
  }
  .flickity-prev-next-button.next {
    right: -10px;
  }
}
</style>
    