import Vue from "vue";
import VueI18n from "vue-i18n";
import { messages } from "../lang";

Vue.use(VueI18n);

// let locale = navigator.language.split("-")[0];
let locale = localStorage.getItem("language") 
  ? localStorage.getItem("language").toLowerCase()
  : "th";


export default new VueI18n({
  fallbackLocale: "th",
  locale,
  messages,
  silentFallbackWarn: true,
});
