import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#0c3e5c",
                secondary: "#a1c8d3",
                accent: "#fbe4e5",
                error: "#f44336",
            }
        }
    }
});
