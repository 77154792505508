import axios from "axios";
const Service = axios.create({
  baseURL:
    "https://api.sudrukresort.com/api/v1/SudRuk",
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
});
Service.interceptors.response.use(undefined, function (error) {
  if (error) {
      if (error.response.status === 500) {
          console.log('Server error');
      }
  }
})


export default Service;
