<template>
  <section id="contact">
    <v-footer dark padless>
      <v-row class="pa-0 ma-0">
        <v-col cols="12" md="4" style="background-color: #516489">
          <v-img src="/logo-page.png" width="160" height="160" class="mx-auto my-2">
          </v-img>
          <v-row class="text-center pa-0 ma-0" style="height: 200px">
            <v-col cols="12" v-for="(icon, i) in icons" :key="i" class="pa-1">
              <v-row class="ma-0 footer-text">
                <v-btn
                  icon
                  class="mx-2"
                  :href="icon.redirectPath"
                  style="font-size: 25px; margin-top: 7px"
                >
                  <img :src="icon.pathImage" style="width: 25px" />
                </v-btn>
                <p class="mt-4" style="font-size: 16px">
                  {{ icon.redirectPath }}
                </p>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="8" style="background-color: pink">
          <h1 class="text-start pt-5 pl-5 header-title">
            {{ $t("contact") }}
          </h1>
          <v-divider
            class="border-opacity-100 ml-6 line-divider"
            color="#0c3e5c"
          ></v-divider>
          <p
            class="pl-5 pt-5"
            v-html="contact.descriptionHtml"
            style="color: #0c3e5c;font-size: 16px;"
          ></p>
        </v-col>
      </v-row>
    </v-footer>
  </section>
</template>
  
  <style scoped>
.v-card {
  width: 100%;
}
.footer-text {
  padding-left: 25%;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .footer-text {
    padding-left: 32% !important;
  }
}
@media (max-width: 767.98px) {
  .footer-text {
    padding-left: 15% !important;
  }
}
</style>
  
  <script>
import Service from "@/service/axios-service";
export default {
  name: "FooterSection",
  data: () => ({
    icons: [],
    contact: "",
  }),
  mounted() {
    // this.getSocial();
    this.getContact();
    this.getSocial();
  },
  methods: {
    getSocial() {
      Service.get("/footer/social")
        .then((res) => {
          this.icons = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getContact() {
      const language = localStorage.getItem("language") || "TH";
      Service.get(`/contact?Language=${language}`)
        .then((res) => {
          this.contact = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
  