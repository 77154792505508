<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary color="#faf7f8" right>
      <div class="ma-5" style="justify-content: center; display: flex">
        <img src="/logo-page.png" alt="Logo" style="max-width: 150px" />
      </div>

      <v-list dense>
        <v-list-item
          v-for="([text, link], i) in items"
          :key="i"
          link
          @click="$vuetify.goTo(link)"
        >
          <v-list-item-content>
            <v-list-item-title class="subtitile-1 text-center mt-3">{{
              $t(text)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div class="text-center mt-2">
          <v-col
              cols="12"
              v-for="(icon, i) in icons"
              :key="i"
              class="pa-1 mt-4"
            >
              <v-btn icon class="mx-2"            
              :href="icon.redirectPath"
              >
              <img :src="icon.pathImage" style="width: 25px" />
              </v-btn>
            </v-col>
 
          <div class="mt-2"> 
            <v-btn
              :color="lang == 'TH' ? '#a1c8d3' : '#000000'"
              text
              style="font-size: large"
              @click="changeLang('TH')"
            >
              TH
            </v-btn>
            |
            <v-btn
              :color="lang == 'EN' ? '#a1c8d3' : '#000000'"
              text
              style="font-size: large"
              @click="changeLang('EN')"
            >
              EN
            </v-btn>
          </div>

        
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      :color="color"
      :flat="flat"
      dark
      class="px-2"
      :class="{ expand: flat }"
    >
      <v-toolbar-title>
        <v-img src="/logo-page.png" max-width="50px" />
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-4"
        v-if="isXs"
      />
      <div v-else class="ml-4">
        <v-row>
          <div style="align-self: center">
            <v-btn text @click="$vuetify.goTo('#home')">
              <span class="mx-6" style="font-size: 16px">{{
                $t("home")
              }}</span>
            </v-btn>
            <v-btn text @click="$vuetify.goTo('#service')">
              <span class="mx-6" style="font-size: 16px">{{
                $t("service")
              }}</span>
            </v-btn>
            <v-btn text @click="$vuetify.goTo('#gallery')">
              <span class="mx-6" style="font-size: 16px">{{
                $t("gallery")
              }}</span>
            </v-btn>
            <v-btn text @click="$vuetify.goTo('#promotion')">
              <span class="mx-6" style="font-size: 16px">{{
                $t("promotion")
              }}</span>
            </v-btn>
            <v-btn text @click="$vuetify.goTo('#contact')">
              <span class="mx-6" style="font-size: 16px">{{
                $t("contact")
              }}</span>
            </v-btn>
          </div>

          <div>
            <v-btn
              icon
              class="mx-2"
              v-for="(icon, i) in icons"
              :key="i"
              :href="icon.redirectPath"
              style="font-size: 25px;"
            >
            <img :src="icon.pathImage" style="width: 25px" />
          </v-btn>

            <v-btn
              :color="lang == 'TH' ? '#a1c8d3' : '#ffffff'"
              text
              style="font-size: large"
              @click="changeLang('TH')"
            >
              TH
            </v-btn>
            |
            <v-btn
              :color="lang == 'EN' ? '#a1c8d3' : '#ffffff'"
              text
              style="font-size: large"
              @click="changeLang('EN')"
            >
              EN
            </v-btn>
          </div>
        </v-row>
      </div>
    </v-app-bar>
  </div>
</template>
  
  <style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>
  
  <script>
import Service from "@/service/axios-service";
export default {
  name: "NavbarComponent",
  data: () => ({
    drawer: null,
    isXs: false,
    items: [
      ["home", "#home"],
      ["service", "#service"],
      ["gallery", "#gallery"],
      ["promotion", "#promotion"],
      ["contact", "#contact"],
    ],
    lang: "TH",
    icons: [],
  }),
  props: {
    color: String,
    flat: Boolean,
  },
  created() {
    this.lang = localStorage.getItem("language") || "TH";
    this.getSocial();
  },
  methods: {
    getSocial() {
      Service.get(`/header/social`)
        .then((res) => {
          this.icons = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onResize() {
      this.isXs = window.innerWidth <= 1024;
    },
    changeLang(lang) {
      localStorage.setItem("language", lang);
      this.lang = lang;
      window.location.reload();
    },
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
  